import { useDisclosure } from "@chakra-ui/react";
import {
  ShowBottomSheetProps,
  BottomSheetContext,
} from "app/context/BottomSheetContext";
import { useMemo } from "react";
import { BottomSheet } from "components/BottomSheet/BottomSheet";
import { ReactNode, useState } from "react";
interface BottomSheetProviderProps {
  children: ReactNode;
}
export const BottomSheetProvider: React.FC<BottomSheetProviderProps> = ({
  children,
}) => {
  const [bottomSheetProps, setBottomSheetProps] =
    useState<ShowBottomSheetProps>({
      body: <div></div>,
    });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const showBottomSheet = useMemo(
    () => (showBottomSheetProps: ShowBottomSheetProps) => {
      setBottomSheetProps(showBottomSheetProps);
      onOpen();
    },
    []
  );
  const closeBottomSheet = onClose;

  return (
    <BottomSheetContext.Provider value={{ showBottomSheet, closeBottomSheet }}>
      <BottomSheet
        bottomSheetProps={bottomSheetProps}
        isOpen={isOpen}
        onClose={closeBottomSheet}
      />
      {children}
    </BottomSheetContext.Provider>
  );
};
