export { appRoutes } from "./appRoutes";
export { localStorageKeys } from "./localStorageKeys";
export { config } from "./config";
export const Constants = Object.freeze({
  BASE_URL_ANDROID: "http://localhost",
  BASE_URL_IOS: "capacitor://lorehealthcare.io",
  TRIGGER_WEBVIEW_NAVIGATION: "trigger-webview-navigation",
  DEVICE_REGISTERED: "deviceRegistered",
  DEVICE_TOKEN: "deviceToken",
  ENROLLMENT_FAQ_HREF: "https://www.lorehealthcare.com/faq",

  DesktopPlatform: "desktop",
  MobileDesktopPlatform: "mobileweb",

  FIREBASE: {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    firebaseConfig: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
  },
  FEEDBACK: {
    ALLOWED_FILETYPES: process.env.REACT_APP_FEEDBACK_FILETYPES,
    MAX_FILE_SIZE: process.env.REACT_APP_FEEDBACK_MAX_FILE_SIZE,
    MAX_FILE_COUNT: process.env.REACT_APP_FEEDBACK_MAX_FILE_COUNT,
    GOOGLE_RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
  },
  ERROR_TITLE: "Something went wrong.",
  ERROR_CODES: {
    DISABLED_USER: 423,
    CONFLICT: 409,
    DUPLICATE_USERNAME: 4091,
    DUPLICATE_USER: 4092,
  },
});
