import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DesktopNavItemId } from "components/Header/DesktopHeader/DesktopNavItems";
import { PromptAction } from "DTOs/PromptDTO";
import { WindowSize } from "app/types/appType";
export interface AppState {
  isShowMobileTab: boolean;
  windowSize: WindowSize;
  activeHeaderNavLink: DesktopNavItemId;
  bannerAlert: BannerAlertState | undefined;
  isNetworkConnected: boolean;
  showBackButton: boolean;
}
interface BannerAlertState {
  description: string;
  title: string;
  action: PromptAction;
}

const initialState: AppState = {
  isShowMobileTab: false,
  windowSize: { width: 0, height: 0 },
  activeHeaderNavLink: DesktopNavItemId.home,
  bannerAlert: undefined,
  isNetworkConnected: true,
  showBackButton: false
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsShowMobileTab: (state, action: PayloadAction<boolean>) => {
      state.isShowMobileTab = action.payload;
    },
    setWindowSize: (state, action: PayloadAction<WindowSize>) => {
      state.windowSize = action.payload;
    },
    setActiveHeaderNavLink: (state, action: PayloadAction<DesktopNavItemId>) => {
      state.activeHeaderNavLink = action.payload;
    },
    setBannerAlert: (state, action: PayloadAction<BannerAlertState | undefined>) => {
      state.bannerAlert = action.payload;
    },
    setIsNetworkConnected: (state, action: PayloadAction<boolean>) => {
      state.isNetworkConnected = action.payload;
    },
    setShowBackButton: (state, action: PayloadAction<boolean>) => {
      state.showBackButton = action.payload;
    },
  },
});

export const {
  setIsShowMobileTab,
  setWindowSize,
  setActiveHeaderNavLink,
  setBannerAlert,
  setIsNetworkConnected,
  setShowBackButton
} = appSlice.actions;
export default appSlice.reducer;
