export enum appRoutes {
  ROOT = "/",
  HOME_PAGE = "/home",
  COMMUNITY = "/community",
  SIGNIN = "/signin",
  SIGNOUT = "/signout",
  SIGNIN_ERROR = "/auth/error",
  SIGNUP_PAGE = "/auth/signup",
  FAQ_PAGE = "/auth/signup/faq",
  PRIVACY_PAGE = "/auth/signup/privacy",
  TERMS_OF_USE_PAGE = "/auth/signup/terms-of-use",
  INTERESTS_PAGE = "/auth/signup/interests",
  THANK_YOU_PAGE = "/auth/signup/thankyou",
  ACCOUNT_DELETED = "/auth/signin/account-deleted",
  FEATURES_PAGE = "/features",
  CHALLENGES_PAGE = "/challenges",
  CHALLENGE_DETAILS_PAGE = "/challenges/details",
  ONBOARDING_PAGE = "/onboarding",
  SETTINGS_PAGE = "/features/settings",
  SETTINGS_PAGE_ACCOUNT = "/features/settings:account",
  SETTINGS_PAGE_PREFRENCES = "/features/settings:preferences",
  ENROLLMENT_LANDING_PAGE = "/landing",
  ENROLLMENT_LANDING_PAGE_V4 = "/lore-landing",
  ENROLLMENT_PAGE = "/enrollment",
  ENROLLMENT_LORE_PAGE_V4 = "/lore-enrollment",
  ENROLLMENT_REGISTER = "/features/enrollment/register/personal-info",
  ENROLLMENT_REGISTER_ADDRESS = "/features/enrollment/register/address",
  ENROLLMENT_REGISTER_PCP = "/features/enrollment/register/pcp",
  ENROLLMENT_HANGTIGHT = "/features/enrollment/hangtight",
  //WT Pages
  ALERTS_PAGE = "/features/alerts",
  CREATE_POST_PAGE = "/features/post",
  NEW_POST_PAGE = "/newpost",
  MESSAGES_PAGE = "/messages",
  SEARCH_PAGE = "/search",
  PROFILE = "/profile",
}
