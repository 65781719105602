import { LoginOptions, MsAuthPlugin } from "@recognizebv/capacitor-plugin-msauth";
import {
  onLoginStarted,
  onLoginCancelled,
  onLogoutSuccess,
  onLogoutFailed,
  AuthStateStatus,
} from "app/slices/authSlice";
import { store } from "app/state/store";
import { b2cPolicies } from "./authConfig";
import { getScopes } from "./helper";

export const getOptions = (): LoginOptions => {
  return {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    tenant: `${process.env.REACT_APP_AUTHORITY}`,
    authorityType: "B2C",
    authorityUrl: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    // domainHint: "<domainHint>",
    scopes: getScopes(),
    keyHash: `${process.env.REACT_APP_ANDROID_KEY_HASH}`,
  };
};

export interface AuthResult {
  accessToken: string;
  idToken: string;
  scopes: string[];
  account?: any;
}

const loginUser = async (): Promise<AuthResult | null> => {
  try {
    store.dispatch(onLoginStarted());
    const { accessToken, idToken, scopes } = await MsAuthPlugin.login(getOptions());
    return {
      accessToken,
      idToken,
      scopes,
    };
  } catch (error) {
    store.dispatch(onLoginCancelled());
    throw error;
  }
};

const logoutUser = async (): Promise<void> => {
  try {
    await MsAuthPlugin.logout(getOptions());
    store.dispatch(onLogoutSuccess());
  } catch (error) {
    console.log("Logout failed: ", error);
    store.dispatch(onLogoutFailed());
  }
};

const getAccessToken = async (scopes?: string[]): Promise<string> => {
  try {
    if (
      store.getState().auth.status === AuthStateStatus.FetchTokenSuccess ||
      store.getState().auth.status === AuthStateStatus.LoginSuccess
    ) {
      const result = await MsAuthPlugin.login(getOptions());
      console.log("access token", result.accessToken);
      return result.accessToken;
    }
  } catch (error) {
    console.log("Unable to fetch access token: ", error);
  }

  return "";
};

export { loginUser, getAccessToken, logoutUser };
