import { config } from "app/constants";
import { isDefined } from ".";

const scriptTagId = "embedded_forum";
const createScript = (url: string) => {
  const fdiv = document.getElementById("wtEmbedCode");
  const script = document.createElement("script");
  script.src = `${process.env.REACT_APP_FORUM_SUBDOMAIN}/js/mb/embed.js#/${url}`;

  script.id = scriptTagId;

  if (fdiv) {
    fdiv.appendChild(script);
  } else {
    // one of the reason why the wt is not loaded is because the wtEmbedCode div tag is not yet rendered
    isElementLoaded("#wtEmbedCode").then((d) => {
      const wtDev = document.getElementById("wtEmbedCode");
      wtDev && wtDev.appendChild(script);
    });
  }
};

//check element
const isElementLoaded = async (selector: string) => {
  while (document.querySelector(selector) === null) {
    await new Promise((resolve) => requestAnimationFrame(resolve));
  }
  return document.querySelector(selector);
};

export const initEmbedScript = (url: string) => {
  const existingScript = document.getElementById(scriptTagId);
  if (!existingScript) {
    createScript(url);
  }
};
export const reloadEmbedScript = (url: string) => {
  const fdiv = document.getElementById("wtEmbedCode");
  const existingScript = document.getElementById(scriptTagId);
  if (existingScript) {
    if (fdiv) fdiv.innerHTML = config.EMPTY_STRING;
    createScript(url);
  }
};
export const isWTurl = () => window.location?.search?.includes("?p=")
export const getCurrentWTurl = () => isWTurl() ? decodeURIComponent(window.location.search.split("?p=")?.[1]) : undefined
export const isWTScriptloaded = () => isDefined(document.getElementsByTagName('iframe')?.[0]) 