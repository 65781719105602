import { forumWebhookProvider } from "./provider/forumWebhook.provider";
import { UserClaimsDto } from "./provider/types";

const extendedApi = forumWebhookProvider.injectEndpoints({
  endpoints: (builder) => ({
    postPimGraphFirstSignin: builder.mutation<any, string>({
      query: (ssoObjectId) => ({
        url: `/pim/graph/firstsignin`,
        method: "POST",
        body: { sso_object_id: ssoObjectId },
      }),
    }),
    postSendEmailVerification: builder.mutation<any, string>({
      query: (ssoObjectId) => ({
        url: `/pim/graph/sendemailverification`,
        method: "POST",
        body: { sso_object_id: ssoObjectId },
      }),
    }),
    getUserClaims: builder.query<UserClaimsDto, void>({
      query: () => ({
        url: `/pim/graph/userclaims`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  usePostPimGraphFirstSigninMutation,
  usePostSendEmailVerificationMutation,
  useLazyGetUserClaimsQuery,
} = extendedApi;
