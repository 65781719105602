import { AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ReactNode } from "react";
import { b2cPolicies } from "app/auth/authConfig";
import { finalizeLogin } from "app/auth/finalizeLogin";
import { useNavigateTo } from "app/hooks/useNavigateTo";
import { usePostPimGraphFirstSigninMutation } from "app/services/pim.service";
import { App as CapApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { getIdTokenClaims } from "app/auth/helper";
import { localStorageHelper } from "app/helpers/localStorageHelper";
import { useAppEffect } from "app/hooks/useAppEffect";
import { useEmail } from "app/hooks/useEmail";
import { useSignalR } from "app/hooks/useSignalR";
import { useWTMessages } from "app/hooks/useWTMessages";
import {
  AuthenticatedUser,
  onSignupSuccess,
  onLoginSuccess,
  resetAuthState,
} from "app/slices/authSlice";
import { useAppDispatch } from "app/state/hooks";
import { setOnlineStatus } from "app/utils";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { updateUrl } from "app/slices/urlSlice";
import detectUrlChange from "detect-url-change";
import useIpAddr from "app/hooks/useIpAddr";
import { setIpAddress } from "app/slices/pubNubSlice";
import { setLoadingStatus } from "app/slices/userSlice";
import { LoadingTypes } from "app/types/LoadingTypes";

interface MsalProps {
  children: ReactNode;
}
export const Msal: React.FC<MsalProps> = ({ children }) => {
  const { initSignalR, closeSignalR } = useSignalR();
  const ldClient = useLDClient();
  const { email } = useEmail();
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigateTo();
  const [pimGraphTrigger] = usePostPimGraphFirstSigninMutation();
  const { instance, inProgress } = useMsal();
  const { ip } = useIpAddr();

  const handleCallBackResponse = async (
    response: AuthenticationResult | null
  ) => {
    localStorageHelper.setItem("loginRequest", "");
    const firstSignin = async (objectId: string) => {
      // This api request will trigger the email confirmation service.
      await pimGraphTrigger(objectId)
        .then(() => {
          // console.log("Send email confirmation success.");
          return;
        })
        .catch((err) => {
          // console.error("Error sending email confirmation: ", err);
        });

      return;
    };

    /*
       The response will always equal to null when in native platform
       Change password will return the response but will be discarded since
       we already have an active account.
       Only the signed-in and signed-up flow in web will get through it
       On native only the signed-up
       and display user
    */
    // console.log({ response });
    // console.log({ inProgress });

    if (
      response !== null &&
      inProgress === InteractionStatus.Startup &&
      !instance.getActiveAccount() &&
      response.authority.toLocaleLowerCase() ===
        b2cPolicies.authorities.signUpSignIn.authority.toLowerCase()
    ) {
      dispatch(setLoadingStatus(LoadingTypes.pending));
      // Display signed-in user content, call API, etc.
      const account = response.account;
      instance.setActiveAccount(account);
      const claims = getIdTokenClaims(account);

      if (Capacitor.isNativePlatform() && claims?.newUser) {
        localStorageHelper.setItem("signup.new-user", true);
      }

      if ((claims?.extension_Userhassignedin as boolean) === false) {
        await firstSignin(account?.localAccountId || "");
      }

      const authResult = {
        email: email,
        username: account?.username,
        extension_Userhassignedin: claims?.["extension_Userhassignedin"],
        isNewUser: claims?.newUser,
        oid: account?.localAccountId,
      } as AuthenticatedUser;

      if (claims?.newUser) {
        dispatch(onSignupSuccess(authResult));
      } else {
        dispatch(onLoginSuccess(authResult));
      }

      await finalizeLogin(authResult, navigateTo);
    } else {
      // In case multiple accounts exist, you can select
      const currentAccounts = instance.getAllAccounts();

      if (currentAccounts.length === 0) {
        // no accounts signed-in, attempt to sign a user in
        // console.error("no accounts signed-in");
        if (!Capacitor.isNativePlatform()) {
          dispatch(resetAuthState());
        }
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
        console.error("multiple accounts");
      } else if (currentAccounts.length === 1) {
        // after change password user is push back to the page where it was left of.
        // console.log("redirect to state", response?.state);
        if (response?.state) {
          window.location.href = response?.state;
        }
      }
    }
  };

  /* MSAL callback response */
  instance.handleRedirectPromise().then(handleCallBackResponse);

  useAppEffect(() => {
    ldClient?.identify({ key: email });
  }, [email, ldClient?.getUser()]);
  useWTMessages();
  useAppEffect(() => {
    Network.getStatus().then(() => {
      setOnlineStatus();
    });
    Network.addListener("networkStatusChange", () => {
      setOnlineStatus();
    });

    CapApp.addListener("resume", () => {
      if (Capacitor.isNativePlatform()) {
        setOnlineStatus();
        initSignalR();
      }
    });
    CapApp.addListener("pause", () => {
      if (Capacitor.isNativePlatform()) closeSignalR();
    });
  }, []);

  useAppEffect(() => {
    const handleUrlChange = (newUrl: string) => {
      dispatch(updateUrl(newUrl));
    };

    detectUrlChange.on("change", handleUrlChange);

    return () => {
      detectUrlChange.off("change", handleUrlChange);
    };
  }, [dispatch]);

  useAppEffect(() => {
    if (ip) {
      dispatch(setIpAddress(ip));
    }
  }, [dispatch, ip]);

  return <>{children}</>;
};
