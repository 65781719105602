export interface PromptDTO {
  type: PromptType;
  display: boolean;
  title_text: string;
  body_text: string;
  bot_name?: string;
  info_text?: string;
  actions: { action: PromptAction }[];
}

export enum PromptType {
  popup = "popup",
  banner = "banner",
}
export interface PromptAction {
  type: PromptActionTypes;
  data: PromptActionData;
}

export enum PromptActionTypes {
  signIn = "sign_in",
  ignore = "ignore",
  createAccount = "create_account",
  viewDirectMessage = "view_direct_message",
  new_discussion = "new_discussion",
  reply = "reply",
  add_interest = "add_interest",
  remove_interest = "remove_interest",
  adjust_settings = "adjust_settings",
  read_post = "read_post",
  replyPost = "replyPost",
}

export interface PromptActionData {
  display_text?: string;
  message_id?: string;
  post_id?: string;
  thread_id?: string;
  interest_id?: string;
}

export enum PromptFrom {
  home = "home",
  search = "search",
  like = "like",
  dislike = "dislike",
  features = "features",
  challenges = "challenges",
  read = "read",
  newMessage = "newMessage",
  replyMessage = "replyMessage",
  newPost = "newPost",
  replyPost = "replyPost",
  explore = "explore",
}
export interface PromptsPostData {
  //like-dislike-read
  postId?: string;
  threadId?: string;
  //
  //search
  query?: string;
  //
  //newMessage-replyMessage
  recepients?: string[];
  subject?: string;
  message?: string;
  conversationId?: string;
  //
}

