import { useState, useEffect } from "react";
import { useLazyGetClientIpQuery } from "app/services/external.service";

export default function GetIP() {
  const [ip, setIp] = useState<string>();
  const [getClientIp] = useLazyGetClientIpQuery();

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    // const response = await fetch("https://ipapi.co/json/");
    const resp = await getClientIp();

    if (resp.data) {
      const data = resp.data;
      setIp(data.IPv4);
    }
  };

  useEffect(() => {
    if (!ip) getIp();
  }, [ip]);

  return {
    ip,
  };
}
