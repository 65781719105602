export enum WtRoutes {
  Inbox = "pm/inbox",
  Alerts = "notifications",
  Logout = "register/logout",
  ForMe = "custom?action=showPersonalizedFeed",
  MyFeed = "search?do=subscribed&action=doSearch",
  Recent = "latest",
  CreatePost = "post/printadd",
  AlertSettings = "register?action=preferences",
  Search = "search?action=doSearch&keywords=",
  Post = "post/",
  Profile = "profile",
}
