export enum Browser {
    chrome = "chrome",
    firefox = "firefox",
    safari = "safari",
    opera = "opera",
    edge = "edge",
    none = ''
}
export const deviceUtils = {
    browserDetect: (): Browser => {
        const userAgent = navigator.userAgent.toLowerCase();
        let browserName: Browser;
        if (userAgent.match(/edg/i)) {
            browserName = Browser.edge;
        }
        else if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = Browser.chrome;
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = Browser.firefox;
        } else if (userAgent.match(/safari/i)) {
            browserName = Browser.safari;
        } else if (userAgent.match(/opr\//i)) {
            browserName = Browser.opera;
        } else {
            return Browser.none;
        }
        return browserName;
    }
}