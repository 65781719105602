import { Box } from "@chakra-ui/react";
export interface BotModalButtonProps {
  text: string;
  fontWeight?: "bold" | "normal";
  onClick?: () => void;
  showBtn?: boolean;
}
export const BotModalButton: React.FC<BotModalButtonProps> = ({
  text,
  onClick,
  showBtn = true,
  fontWeight = "normal",
}) => {
  return (
    <>
      {showBtn && (
        <Box
          id={`bot-modal-button-${text}`}
          cursor="pointer"
          onClick={onClick}
          p={1}
          width="100%"
          borderTop="1px"
          borderColor="gray.300"
          color="brand.main"
          fontWeight={fontWeight}
        >
          {text}
        </Box>
      )}
    </>
  );
};
