import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";

import urlReducer, { updateUrl } from "../slices/urlSlice";
import { parseGuid } from "app/utils/ParseValidGuidUtils";
import { externalServicesProvider } from "app/services/provider/externalServices.provider";
import { forumWebhookUnAuthProvider } from "app/services/provider/forumWebhook.provider";
import { UserEngamentDataDTO } from "DTOs/SharedPostDTO";
import { RootState, AppDispatch, store } from "./store";

async function getClientIpAddress(dispatch: any, state: any) {
  const resp = await (externalServicesProvider.endpoints as any).getClientIp.initiate()(
    dispatch,
    state,
    {}
  );

  return resp.data.IPv4;
}

async function addSharePostEngagement(data: UserEngamentDataDTO, dispatch: any, state: any) {
  await (forumWebhookUnAuthProvider.endpoints as any).userEngagement.initiate(data)(
    dispatch,
    state,
    {}
  );
}

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
listenerMiddleware.startListening({
  actionCreator: updateUrl,
  effect: async (action, listenerApi) => {
    const refUserId = store.getState().user.refUserId || null;

    listenerApi.cancelActiveListeners();
    // Run whatever additional side-effect-y logic you want here
    console.log("URL changed: ", action.payload);

    const url = new URL(action.payload);
    const searchParams = new URLSearchParams(url.search);
    const guidParam = searchParams.get("sguid");
    // Can cancel other running instances

    // Run async logic
    if (parseGuid(guidParam || "")) {
      const ipAddress = await getClientIpAddress(listenerApi.dispatch, store.getState);

      const data: UserEngamentDataDTO = {
        sharePostGuid: guidParam || "",
        refUserId: refUserId,
        ipAddress: ipAddress,
      };

      await addSharePostEngagement(data, listenerApi.dispatch, store.getState);
    }
  },
});

export default listenerMiddleware;
