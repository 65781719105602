import {
  loginUser,
  logoutUser,
  getAccessToken,
  AuthResult,
} from "../../auth/authenticationSession";
import { MsalProvider } from "@azure/msal-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { AuthStateStatus } from "app/slices/authSlice";
import { useNavigateTo } from "app/hooks/useNavigateTo";
import { useNotification } from "app/hooks/useNotification";
import { signout } from "app/auth/signoutHelper";

type AuthProviderProps = { children: React.ReactNode };

export interface IAuthContext {
  isAuthenticated: boolean;
  authenticationStatus: AuthStateStatus;
  login: Function;
  logout: Function;
  acquireAccessTokenForUser: Function;
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  acquireAccessTokenForUser: () => {},
  authenticationStatus: AuthStateStatus.None,
});

const useAuthContext = () => useContext(AuthContext);

const useAuthentication = (): IAuthContext => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [isLogonFlag, setIsLogonFlag] = useState<boolean>(isAuthenticated);
  const authenticationStatus = useAppSelector((x) => x.auth.status);

  const login = async (): Promise<AuthResult | null> => {
    try {
      return await loginUser();
    } catch (error) {
      throw error;
    }
  };

  async function completeSignout(): Promise<void> {
    try {
      await logoutUser(); // signout user from msauth in native
      await signout(); // complete signout with clean up
    } catch (error) {}
  }

  const acquireAccessTokenForUser = async (
    scopes?: string[]
  ): Promise<string> => {
    return await getAccessToken();
  };

  useEffect(() => {
    setIsLogonFlag(isAuthenticated);
  }, [isAuthenticated]);

  return {
    isAuthenticated: isLogonFlag,
    logout: completeSignout,
    login,
    acquireAccessTokenForUser,
    authenticationStatus,
  };
};

const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuthentication();

  return (
    <AuthContext.Provider value={auth}>
      <MsalProvider instance={globalThis.msalInstance}>{children}</MsalProvider>
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuthContext };
