import { useIsAuthenticated } from "@azure/msal-react";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { useNotification } from "app/hooks/useNotification";
import { setnotificationPermissionState } from "app/slices/notificationsSlice";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { Browser, deviceUtils } from "app/utils/deviceUtils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode, useEffect } from "react";

interface NotificationProviderProps {
  children: ReactNode;
}
export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const {
    initialize,
    deleteToken,
    listenForNotificationAction,
    isTokenInLocalStorage,
  } = useNotification();
  const { enableNotifications } = useFlags();
  const isAuthenticated = useIsAuthenticated();
  const { notificationPermissionState, isNotificationLoading } = useAppSelector(
    (s) => s.notifications
  );

  useEffect(() => {
    if (
      enableNotifications === false ||
      (!!notificationPermissionState &&
        notificationPermissionState !== "granted" &&
        isTokenInLocalStorage())
    ) {
      deleteToken();
      return;
    }
    if (isAuthenticated && enableNotifications && !isNotificationLoading) {
      initialize();
    }
  }, [isAuthenticated, enableNotifications, notificationPermissionState]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      listenForNotificationAction();
      PushNotifications.checkPermissions().then((per) => {
        dispatch(setnotificationPermissionState(per.receive));
      });
    } else {
      const isNotificationSupported = () =>
        "Notification" in window &&
        "serviceWorker" in navigator &&
        "PushManager" in window;

      // If Safari macOS (navigator.permissions.query({ name: "notifications" }) is not supported on Safari)
      if (
        deviceUtils.browserDetect() === Browser.safari &&
        isNotificationSupported()
      ) {
        dispatch(setnotificationPermissionState(Notification.permission));
      } else {
        navigator?.permissions
          ?.query({ name: "notifications" })
          .then((permissionStatus) => {
            dispatch(setnotificationPermissionState(permissionStatus?.state));
            permissionStatus.onchange = () => {
              dispatch(setnotificationPermissionState(permissionStatus?.state));
            };
          });
      }
    }
  }, []);

  return <> {children}</>;
};
