import React from "react";

export const PopUpContext = React.createContext<PopUpContextType>({} as PopUpContextType)
export interface PopUpContextType {
    showModal: (showModalProps: ShowModalProps) => void;
    closeModal: () => void;
}

export interface ShowModalProps {
    body: React.ReactNode;
    title?: React.ReactNode;
    footer?: React.ReactNode;
    closeButton?: boolean;
}