import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "app/auth/authConfig";
import { store, persistor } from "app/state/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "app/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PersistGate } from "redux-persist/integration/react";
import { PopUpProvider } from "app/providers/PopUpProvider";
import { Msal } from "app/providers/Msal";
import CustomNavigationClient from "app/auth/customNavigation";
import { NotificationProvider } from "app/providers/NotificationProvider";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { darklyConfig } from "app/constants/darklyConfig";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { BottomSheetProvider } from "app/providers/BottomSheetProvider";
import AppUrlListener from "app/providers/AppUrlListener";
import { AuthContextProvider } from "app/providers/auth";

var inIframe = window !== parent;
if (inIframe) {
  parent.location.replace(window.location.href);
}
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const navigationClient = new CustomNavigationClient();
msalInstance.setNavigationClient(navigationClient);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

globalThis.msalInstance = msalInstance;

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
if (Capacitor.isNativePlatform()) {
  StatusBar.setStyle({ style: Style.Dark });
  StatusBar.setBackgroundColor({ color: "#000000" });
  StatusBar.show();
}

const Providers = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AppUrlListener />
        <AuthContextProvider>
          <ChakraProvider theme={theme} resetCSS>
            <PopUpProvider>
              <BottomSheetProvider>
                <NotificationProvider>
                  <Msal>
                    <App />
                  </Msal>
                </NotificationProvider>
              </BottomSheetProvider>
            </PopUpProvider>
          </ChakraProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
const ProvidersWithLD = withLDProvider(darklyConfig)(Providers);

root.render(
  <React.StrictMode>
    <ProvidersWithLD />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
