import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Box } from "@chakra-ui/react";
type Props = {};

const NewAppNotifier = (props: Props) => {
  const [isNativeApp, setIsNativeApp] = useState(false);
  const [countDown, setCountDown] = useState(5);

  const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}`;
  useEffect(() => {
    const isNative = Capacitor.isNativePlatform();
    setIsNativeApp(isNative);
    if (!isNative) {
      let count = 5;
      let timer = setInterval(() => {
        if (count <= 0) {
          clearInterval(timer);
          window.location.href = redirectUrl;
        } else {
          setCountDown(--count);
        }
      }, 1000);
    }
  }, []);

  return (
    <>
      {!isNativeApp && (
        <Box marginTop={"auto"} marginBottom={"auto"} width={"70%"} textAlign={`center`}>
          <p>
            Lore has moved! We’re taking you to our updated Lore website. You can also use this
            address to go there directly: <a href={redirectUrl}>{redirectUrl.split("/")[2]}</a>
          </p>
          <p>Please note that you may be asked to sign in again on the new site.</p>
          <br />
          <p>Redirecting in {countDown}</p>
        </Box>
      )}
    </>
  );
};

export default NewAppNotifier;
