import { PostDTO } from "DTOs/PostDTO";
import React from "react";

export const BottomSheetContext = React.createContext<BottomSheetContextType>(
  {} as BottomSheetContextType
);
export interface BottomSheetContextType {
  showBottomSheet: (showModalProps: ShowBottomSheetProps) => void;
  closeBottomSheet: () => void;
}

export interface ShowBottomSheetProps {
  body: React.ReactNode;
  closeButton?: boolean;
}
