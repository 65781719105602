import { useNavigate } from "react-router-dom";
import { appRoutes } from "app/constants";
import { reloadEmbedScript } from "app/utils/embedUtils";

export const useNavigateTo = () => {
  const navigate = useNavigate();

  const navigateTo = <T>(
    to: appRoutes | string,
    state?: T,
    replace?: boolean
  ) => {
    navigate(to, { state, replace });

  };

  const navigateWithEmbedUrl = (
    route: appRoutes,
    url: string,
    replace = true
  ) => {
    const prevPathname = window.location.pathname;
    navigate(`${route}?p=${url}`, { replace });
    if (prevPathname.includes(route)) {
      reloadEmbedScript(url);
    }
  };

  const navigateBack = () => {
    navigate(-1)
  }

  return { navigateTo, navigateWithEmbedUrl, navigateBack };
};
