import { appRoutes } from "app/constants";
import { WtIds } from "app/constants/WtIds";
import { WtRoutes } from "app/constants/WtRoutes";
import { FeatureFlags } from "app/types/FeatureFlags";
export enum DesktopNavItemId {
  home = "home-id",
  challenges = "challenges-id",
  messages = "messages-id",
  alerts = "alerts-id",
  features = "feature-id",
}
export interface DesktopNavItem {
  id: DesktopNavItemId;
  label: string;
  route: appRoutes;
  hasBadge?: boolean;
  canNavigateWithoutAuth?: boolean;
  badgeName?: string;
  featureFlag?: keyof FeatureFlags;
  WtRoute?: WtRoutes;
  WtId?: WtIds;
  alwaysShown?: boolean;
}
export const desktopNavItems: DesktopNavItem[] = [
  {
    id: DesktopNavItemId.home,
    label: "Home",
    route: appRoutes.HOME_PAGE,
    hasBadge: false,
    canNavigateWithoutAuth: true,
    WtRoute: WtRoutes.ForMe,
    WtId: WtIds.home,
    alwaysShown: true,
  },
  {
    id: DesktopNavItemId.messages,
    label: "Messages",
    route: appRoutes.MESSAGES_PAGE,
    hasBadge: true,
    canNavigateWithoutAuth: false,
    badgeName: "messages",
    WtId: WtIds.messages,
    WtRoute: WtRoutes.Inbox,
    alwaysShown: false,
  },
  {
    id: DesktopNavItemId.alerts,
    label: "Alerts",
    route: appRoutes.ALERTS_PAGE,
    hasBadge: true,
    canNavigateWithoutAuth: false,
    badgeName: "notifications",
    featureFlag: "alertsNav",
    WtId: WtIds.alerts,
    WtRoute: WtRoutes.Alerts,
    alwaysShown: false,
  },
  {
    id: DesktopNavItemId.features,
    label: "Features",
    route: appRoutes.FEATURES_PAGE,
    hasBadge: false,
    canNavigateWithoutAuth: true,
    alwaysShown: true,
  },
];
