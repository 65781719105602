import { appRoutes, config } from "app/constants";
import { setIsNetworkConnected } from "app/slices/appSlice";
import { store } from "app/state/store";
import axios, { AxiosError } from "axios";
/* eslint-disable @typescript-eslint/no-explicit-any */
export function debounce<F extends (...params: any[]) => void>(
  fn: F,
  delay: number
) {
  let timeoutID: number | null = null;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID || 0);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

export const isValidEmail = (email: string) => {
  if (!email) {
    return false;
  }
  return email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
};
export const setOnlineStatus = () => {
  const dispatch = store.dispatch;
  axios
    .get(`${process.env.REACT_APP_FORUM_WEBHOOK_API_BASE_URL}/isOnline`)
    .catch((e) => {
      if (e?.code === AxiosError.ERR_NETWORK) {
        dispatch(setIsNetworkConnected(false));
        setTimeout(() => {
          setOnlineStatus();
        }, 3000);
      } else if (e?.code === AxiosError.ERR_BAD_REQUEST) {
        dispatch(setIsNetworkConnected(true));
      }
    });
};

export const isNotDefined = (object: any): boolean =>
  object === null || object === undefined;
export const isDefined = (object: any): boolean =>
  object !== null && object !== undefined;
export const isEmpty = (text: string | undefined | null): boolean =>
  text === config.EMPTY_STRING || isNotDefined(text);
export { DateUtils } from "./DateUtils";
