export const parseGuid = (guidParam: string): string | undefined => {
  if (guidParam) {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (guidRegex.test(guidParam)) {
      return guidParam;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
