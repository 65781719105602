import { Flex, Image, Avatar, Box, Text, Icon, Center } from "@chakra-ui/react";
import { BotModalButtonProps, BotModalButton } from "./BotModalButton";
import bot from "app/assets/bot.png";
import botBG from "app/assets/bot-bg.svg";
import { MdInfoOutline } from "react-icons/md";
interface BotModalProps {
  buttons: BotModalButtonProps[];
  title: string;
  summary?: string;
  subTitle?: string;
  bannerSrc?: string;
  container?: JSX.Element;
}
export const BotModal: React.FC<BotModalProps> = ({
  buttons,
  title,
  summary,
  subTitle = "Lore Bot",
  bannerSrc = botBG,
  container,
}) => {
  return (
    <>
      <Flex justifyContent="space-between" id="bot-modal-title-container">
        <Text color="brand.600" fontWeight="bold" m={3} id="bot-modal-title">
          {title}
        </Text>
        <Center id="bot-modal-i-icon-container">
          <Icon
            m={3}
            as={MdInfoOutline}
            color="brand.main"
            id="bot-modal-i-icon"
          />
        </Center>
      </Flex>
      <Box w="full" h="120px">
        <Image src={bannerSrc} w="full" height="70px" objectFit="cover" />
        <Flex
          top="-50px"
          position="relative"
          direction="row"
          alignItems="flex-end"
          id="bot-modal-avatar-container"
        >
          <Avatar
            id="bot-modal-avatar-container"
            size="xl"
            ml={3}
            borderColor="white"
            borderWidth="2px"
            backgroundColor="white"
            name="Bot"
            src={bot}
          />
          <Text
            color="brand.600"
            fontWeight="bold"
            ml={2}
            id="bot-modal-subtitle"
          >
            {subTitle}
          </Text>
        </Flex>
      </Box>
      {/* <Box
        id="bot-modal-banner-image"
        backgroundImage={bannerSrc}
        backgroundRepeat="repeat-x"
        backgroundSize="auto 50px"
      >
        <Box id="bot-modal-empty-box" height="10px"></Box>

        <Flex
          direction="row"
          alignItems="flex-end"
          id="bot-modal-avatar-container"
        >
          <Avatar
            id="bot-modal-avatar-container"
            size="lg"
            ml={3}
            borderColor="white"
            borderWidth="2px"
            backgroundColor="white"
            name="Bot"
            src={bot}
          />
          <Text
            color="brand.600"
            fontWeight="bold"
            ml={2}
            id="bot-modal-subtitle"
          >
            {subTitle}
          </Text>
        </Flex>
      </Box> */}
      {summary && (
        <Text
          p={3}
          color="appGray"
          lineHeight={1.2}
          whiteSpace="pre-line"
          id="bot-modal-summary"
        >
          {summary}
        </Text>
      )}
      {container ? (
        <Box p={3} id="bot-modal-container-box">
          {container}
        </Box>
      ) : (
        <></>
      )}
      <Box width="100%" p={3} id="bot-modal-buttons-container">
        {buttons.map((button, idx) => (
          <BotModalButton
            key={button.text}
            text={button.text}
            showBtn={button.showBtn}
            fontWeight={idx === 0 ? "bold" : "normal"}
            onClick={button.onClick}
          />
        ))}
      </Box>
    </>
  );
};
