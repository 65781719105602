import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type NotificationPermissionType =
  | "default"
  | "prompt"
  | "granted"
  | "denied"
  | "prompt-with-rationale"
  | "pending"
  | undefined;

interface NotificationsState {
  notificationPermissionState?: NotificationPermissionType;
  firebaseMessagingToken?: string | undefined;
  isNotificationLoading?: boolean;
  isNotificationError?: boolean;
  notificationError?: string;
}

const initialState = {
  notificationPermissionState: undefined,
  firebaseMessagingToken: undefined,
  isNotificationLoading: false,
  isNotificationError: false,
  notificationError: undefined,
} as NotificationsState;

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setnotificationPermissionState: (
      state,
      action: PayloadAction<NotificationPermissionType>
    ) => {
      state.notificationPermissionState = action.payload;
    },
    setfirebaseMessagingToken: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.firebaseMessagingToken = action.payload;
    },
    setIsNotificationLoading: (state, action: PayloadAction<boolean>) => {
      state.isNotificationLoading = action.payload;
    },
    setIsNotificationError: (state, action: PayloadAction<boolean>) => {
      state.isNotificationError = action.payload;
    },
    setNotificationError: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.notificationError = action.payload;
    },
  },
});

export const {
  setnotificationPermissionState,
  setfirebaseMessagingToken,
  setIsNotificationError,
  setIsNotificationLoading,
  setNotificationError,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
