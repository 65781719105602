import { useMsal } from "@azure/msal-react";
import { loginRequest } from "app/auth/authConfig";
import { appRoutes } from "app/constants";
import { useNavigateTo } from "./useNavigateTo";
import { useAuthContext } from "app/providers/auth";
import { sessionStorageHelper } from "app/helpers/sessionStorageHelper";

export const useSignup = () => {
  const { instance } = useMsal();
  const { isAuthenticated } = useAuthContext();
  const { navigateTo } = useNavigateTo();
  const singnupHandle = (email?: string) => {
    sessionStorageHelper.clearMsal();
    if (!isAuthenticated) {
      instance
        .loginRedirect({
          ...loginRequest,
          prompt: "login",
          authority: `${process.env.REACT_APP_AUTHORITY}/${process.env.REACT_APP_SUSI_POLICY}`,
          loginHint: email,
          extraQueryParameters: { option: "signup" },
          redirectStartPage: appRoutes.ROOT
        })
    } else if (isAuthenticated) {
      navigateTo(appRoutes.ROOT);
    }
  };
  const signup = () => {
    singnupHandle();
  };
  const signupWithEmail = (email: string) => {
    singnupHandle(email);
  };
  return { signup, signupWithEmail };
};
