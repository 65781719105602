// import { sequelaeProvider } from "./provider/sequelae.provider";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { acquireAccessToken } from "app/auth/helper";

export const interestsExtendedApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SEQUELAE_INTERVENTIONS_PROMPTS + "/api",
    prepareHeaders: async (headers) => {
      const token = await acquireAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  reducerPath: "interestsApi",
  tagTypes: ["Interests"],
  endpoints: (builder) => ({
    getInterests: builder.query({
      query: () => {
        return {
          url: "/interest",
        };
      },
      providesTags: ["Interests"],
    }),
    postInterests: builder.mutation({
      query: (params) => {
        return {
          url: `/userinterest`,
          method: "Post",
          body: params,
        };
      },
      invalidatesTags: ["Interests"],
    }),

    putInterests: builder.mutation({
      query: (params) => {
        return {
          url: `/userinterest`,
          method: "Put",
          body: params,
        };
      },
      invalidatesTags: ["Interests"],
    }),

    deleteInterest: builder.mutation({
      query: (params) => {
        return {
          url: `/api/userinterest`,
          method: "DELETE",
          body: params,
        };
      },
      invalidatesTags: ["Interests"],
    }),
  }),
});
export const {
  useGetInterestsQuery,
  usePostInterestsMutation,
  usePutInterestsMutation,
  useDeleteInterestMutation,
} = interestsExtendedApi;
