import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ChallengeDTO from "DTOs/ChallengeDTO";
import { RootState } from "app/state/store";

interface ChallengesState {
  challenges: ChallengeDTO[];
  recommendedChallanges: ChallengeDTO[];
  selectedChallenge: ChallengeDTO;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState = {
  challenges: [],
  recommendedChallanges: [],
  loading: "idle",
  selectedChallenge: {} as ChallengeDTO,
} as ChallengesState;



const challengesSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {
    selectChallenge: (
      state: ChallengesState,
      action: PayloadAction<ChallengeDTO>
    ) => {
      state.selectedChallenge = action.payload;
    },
    setChallanges: (state: ChallengesState, action: PayloadAction<ChallengeDTO[]>) => {
      state.challenges = action.payload
    },
    setRecommendedChallanges: (state: ChallengesState, action: PayloadAction<ChallengeDTO[]>) => {
      state.recommendedChallanges = action.payload
    },
  }
});
const challangesSelector = (state: RootState) => state.challenges.challenges;
const selectedChallangeSelector = (state: RootState) =>
  state.challenges.selectedChallenge;
export const { selectChallenge, setChallanges } = challengesSlice.actions;
export { challangesSelector, selectedChallangeSelector };
export default challengesSlice.reducer;
