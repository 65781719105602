export const localStorageHelper = {
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  setItem: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getItem: (key: string) => {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  },
  clear: () => {
    localStorage.clear();
  },
};
