import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UrlState {
  currentUrl: string;
}

const initialState: UrlState = {
  currentUrl: window.location.href,
};

const urlSlice = createSlice({
  name: "url",
  initialState,
  reducers: {
    updateUrl: (state, action: PayloadAction<string>) => {
      state.currentUrl = action.payload;
    },
  },
});

export const { updateUrl } = urlSlice.actions;
export default urlSlice.reducer;
