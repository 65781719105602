import { externalServicesProvider } from "./provider/externalServices.provider";

const extendedApi = externalServicesProvider.injectEndpoints({
  endpoints: (builder) => ({
    getClientIp: builder.query<any, void>({
      query: () => ({
        url: "https://geolocation-db.com/json/",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetClientIpQuery, useLazyGetClientIpQuery } = extendedApi;
