import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/state/store";

export interface CountersState {
  messages: number;
  notifications: number;
}

const initialState = {
  messages: 0,
  notifications: 0
} as CountersState;



const countersSlice = createSlice({
  name: "counters",
  initialState,
  reducers: {
    setCounters: (state: CountersState, action: PayloadAction<CountersState>) => {
      state.messages = action.payload.messages;
      state.notifications = action.payload.notifications;
    }
  }
});
const countersSelector = (state: RootState) => state.counters;
export const { setCounters } = countersSlice.actions;
export { countersSelector };
export default countersSlice.reducer;
