import { Box, Flex } from "@chakra-ui/react";
import "./App.css";
import useDetectMobilePlatform from "app/hooks/useDetectMobilePlatform";
import { useAppSelector } from "app/state/hooks";
import { NetworkError } from "components/NetworkError/NetworkError";
import { useAuthContext } from "app/providers/auth";
import { useEffect } from "react";
import { signout } from "app/auth/signoutHelper";
import { appRoutes } from "app/constants";
import { useManualUpdates } from "app/hooks/useManualUpdates";
import { Capacitor } from "@capacitor/core";
import NewAppNotifier from "components/NewAppNotifier";

const App = () => {
  const { isNetworkConnected } = useAppSelector((s) => s.app);

  const { isMobileWebView, isMobileDevice } = useDetectMobilePlatform();

  const { isAuthenticated } = useAuthContext();

  const userData = useAppSelector((s) => s.user.userData);

  useManualUpdates();

  useEffect(() => {
    if ((!isAuthenticated && userData) || (isAuthenticated && !userData)) {
      signout(appRoutes.SIGNIN);
    }
  }, []);
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

    return (
      <Flex
        //h={isMobileWebView || isMobileDevice ? "100vh" : undefined}
        h={"100vh"}
        width="full"
        direction="column"
        align="stretch"
        alignItems="center"
      >
        {Capacitor.isNativePlatform() ? (
          <Box marginTop={"auto"} marginBottom={"auto"} width={"70%"} textAlign={`center`}>
            {Capacitor.getPlatform() == "android" && (
              <>
                Lore has moved! To continue participating in the community, please go to{" "}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    (window.cordova as any).InAppBrowser.open(
                      `https://play.google.com/store/apps/details?id=com.lorehealthcare.community`,
                      "_system",
                      "location=yes"
                    );
                  }}
                >
                  https://play.google.com/store/apps/details?id=com.lorehealthcare.community
                </a>{" "}
                and install the new Lore app. Once you have the new app, we recommend that you
                delete this one.
              </>
            )}
            {Capacitor.getPlatform() == "ios" && (
              <>
                Lore has moved! Please update your app to continue participating in the community:{" "}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    (window.cordova as any).InAppBrowser.open(
                      `https://apps.apple.com/us/app/lore-health/id1636614486`,
                      "_system",
                      "location=yes"
                    );
                  }}
                >
                  https://apps.apple.com/us/app/lore-health/id1636614486
                </a>
              </>
            )}
          </Box>
        ) : (
          <>
            <NewAppNotifier />
            {/* 
            <img id="image-token" style={{ display: "none" }}></img>
            <PubNubWrapper>
              <AppAuthenticatedWrapper>
                <Heap />
                <Routing />
              </AppAuthenticatedWrapper>
              <AppUnauthenticatedWrapper>
                <Routing />
              </AppUnauthenticatedWrapper>
            </PubNubWrapper> */}
          </>
        )}
      </Flex>
    );

};

export default App;
