import { UserDataDTO } from "DTOs/UserDataDTO";
import { appRoutes, Constants } from "app/constants";
import { setImageAuthToken } from "app/helpers/setTokenImage";
import { forumWebhookProvider } from "app/services/provider/forumWebhook.provider";
import { setLoadingStatus, setUserData } from "app/slices/userSlice";
import { store } from "app/state/store";
import { LoadingTypes } from "app/types/LoadingTypes";
import { CountersState, setCounters } from "app/slices/countersSlice";
import { AuthenticatedUser } from "app/slices/authSlice";
import { sessionStorageHelper } from "app/helpers/sessionStorageHelper";
import { sessionStorageKeys } from "app/constants/sessionStorageKeys";
const dispatch = store.dispatch;

export const finalizeLogin = async (
  account: AuthenticatedUser | null,
  navigateTo: (appRoutes: appRoutes) => void
) => {
  if (!account) {
    navigateTo(appRoutes.SIGNIN_ERROR);
    return;
  }
  dispatch(setLoadingStatus(LoadingTypes.pending));
  let result: { data: UserDataDTO & CountersState; error: any };
  if (account?.isNewUser) {
    result = await signUpUser();
  } else {
    result = await getUserData();
  }

  if (result.error) {
    if (result.error.originalStatus === Constants.ERROR_CODES.DISABLED_USER) {
      navigateTo(appRoutes.ACCOUNT_DELETED);
    }
    return;
  }

  let { messages, notifications, ...profile } = result.data;
  let counters: CountersState = { messages, notifications };
  const isUserInAnotherDomain = !profile || profile.id <= 0;
  if (isUserInAnotherDomain) {
    profile = (await signUpUser())?.data;
  }

  dispatch(setUserData(profile));
  dispatch(setCounters(counters));

  if (profile.authToken) {
    setImageAuthToken(profile.authToken, () => {
      dispatch(setLoadingStatus(LoadingTypes.succeeded));
    });
  } else if (!profile.onboardingCompleted) {
    navigateTo(appRoutes.ONBOARDING_PAGE);
    dispatch(setLoadingStatus(LoadingTypes.succeeded));
  }
};

export const signUpUser = async () => {
  const enrollmentGuid = sessionStorageHelper.getItem(sessionStorageKeys.guid);
  const profileResult = await (forumWebhookProvider.endpoints as any).postUser.initiate({
    enrollmentGuid,
  })(dispatch, store.getState, {});
  return profileResult;
};

const getUserData = async () => {
  const profile = await (
    forumWebhookProvider.endpoints as any
  ).getUserData.initiate()(dispatch, store.getState, {});
  return profile;
};
