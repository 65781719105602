import { useAccount } from "@azure/msal-react";
import { getIdTokenClaims } from "app/auth/helper";
import { useAppSelector } from "app/state/hooks";
import { isEmpty } from "app/utils";

export const useEmail = () => {
  let email;

  const account = useAccount();
  const getEmailFromClaims = () => {
    const emails: string[] | undefined = getIdTokenClaims(account)?.["emails"];

    return emails?.[0];

  }
  email = useAppSelector((s) => s.auth.user?.email);
  if (isEmpty(email))
    email = getEmailFromClaims()
  return { email };

};
