import { PromptFrom } from "DTOs/PromptDTO";
import { appRoutes } from "app/constants";
import { useNavigateTo } from "./useNavigateTo";
import { usePrompts } from "./usePrompts";
import { useAppDispatch } from "app/state/hooks";
import { setShowBackButton } from "app/slices/appSlice";

import { isNotDefined } from "app/utils";

export const useWTMessages = () => {
    const { getAllPrompts } = usePrompts({});
    const { navigateTo, navigateWithEmbedUrl, navigateBack } = useNavigateTo();

    const dispatch = useAppDispatch();
    window.onmessage = (event) => {
        const data = event.data;
        switch (data.message) {
            case "Likes":
                const type = data?.data?.type;
                getAllPrompts({
                    from: type,
                    data: { postId: data?.data.postId, threadId: data?.data.threadId },
                });
                break;
            case "NewPost":
                const isNewPostPage = location.pathname.includes(appRoutes.NEW_POST_PAGE);
                const isCreatePostPage = location.pathname.includes(appRoutes.CREATE_POST_PAGE);
                const isPreviewPostClicked = data?.url === '/addmessage';
                const isCancelButtonClicked = isNotDefined(data?.url);
                const isNewPostCreated = data?.url;

                if (isNewPostCreated && !isPreviewPostClicked && (isNewPostPage || isCreatePostPage)) {
                    navigateWithEmbedUrl(appRoutes.HOME_PAGE, `post${data.url}`);
                }
                else if (isCancelButtonClicked) {
                    isNewPostPage ? navigateTo(appRoutes.ROOT) : navigateBack()
                }

                break;
            case PromptFrom.read:
            case PromptFrom.replyMessage:
            case PromptFrom.newMessage:
                getAllPrompts({
                    from: data.message,
                    data: data.data,
                });

                break;
            case "ReplyPostNotLoggedIn":
                navigateTo(appRoutes.SIGNIN);
                break;
            case "ShowBackButton":
                dispatch(setShowBackButton(data.show))
                break;

        }
    };

} 