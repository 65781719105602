import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotifications,
} from "@capacitor/push-notifications";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { initializeApp } from "firebase/app";
import { appRoutes, Constants, localStorageKeys } from "app/constants";
import { FCM } from "@capacitor-community/fcm";
import { localStorageHelper } from "app/helpers/localStorageHelper";
import {
  useRegisterMutation,
  useUnregisterMutation,
} from "app/services/notifications.service";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  setfirebaseMessagingToken,
  setIsNotificationError,
  setIsNotificationLoading,
  setnotificationPermissionState,
} from "app/slices/notificationsSlice";
import { Browser, deviceUtils } from "app/utils/deviceUtils";
import { useNavigateTo } from "./useNavigateTo";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export const useNotification = () => {
  const [register] = useRegisterMutation();
  const [unregister] = useUnregisterMutation();
  const { navigateWithEmbedUrl } = useNavigateTo();
  const { notificationPermissionState } = useAppSelector(
    (s) => s.notifications
  );
  const dispatch = useAppDispatch();
  const initialize = async () => {
    try {
      if (
        isTokenInLocalStorage() &&
        notificationPermissionState === "granted"
      ) {

        dispatch(
          setfirebaseMessagingToken(
            localStorageHelper.getItem(localStorageKeys.deviceToken)
          )
        );
        return;
      }
      if (isBrowserNeedUserActionOnFirstRegistration()
        || isUserSelectToDisableNotification()) {
        return;
      }
      dispatch(setIsNotificationLoading(true));
      await requestPermission();
      const token = await acquireToken();
      await saveToken(token);
      dispatch(setIsNotificationLoading(false));
    } catch (e) {
      dispatch(setIsNotificationLoading(false));
    }
  };

  const isBrowserNeedUserActionOnFirstRegistration = () => {
    const browser = deviceUtils.browserDetect();
    return browser !== Browser.chrome && !localStorageHelper.getItem(localStorageKeys.isNotificationSettingEnabled)
  }
  const isUserSelectToDisableNotification = () => {
    return localStorageHelper.getItem(localStorageKeys.isNotificationSettingEnabled) === "false"
  }
  const isTokenInLocalStorage = (): boolean => {
    return !!localStorageHelper.getItem(localStorageKeys.deviceToken);
  };

  const requestPermission = async () => {
    if (Capacitor.isNativePlatform()) {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive !== "granted") {
        permStatus = await PushNotifications.requestPermissions();
        dispatch(setnotificationPermissionState(permStatus.receive));
      }

      if (permStatus.receive === "denied") {
        throw new Error("User denied permissions!");
      }

      await PushNotifications.register();
    } else {
      // If Safari (requires window.safari.pushNotification.requestPermission())
      if (deviceUtils.browserDetect() === Browser.safari) {
        (window as any).safari.pushNotification.requestPermission(
          process.env.REACT_APP_BASE_URL,
          process.env.REACT_APP_APPLE_PUSH_ID,
          {},
          (permissionData: any) => {
            // Manually update the permission state in case of Safari
            dispatch(setnotificationPermissionState(permissionData.permission));
            if (permissionData.permission !== "granted") {
              throw new Error("User denied permissions!");
            }
          }
        );
      } else {
        let permissionStatus = await Notification.requestPermission();
        if (permissionStatus !== "granted") {
          throw new Error("User denied permissions!");
        }
      }
    }
  };

  const acquireToken = async (): Promise<string> => {
    if (Capacitor.isNativePlatform()) {
      return (await FCM.getToken()).token;
    } else {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      return await getToken(messaging, {
        vapidKey: Constants.FIREBASE.vapidKey,
      });
    }
  };

  const saveToken = async (token: string) => {
    dispatch(setIsNotificationError(false));
    const res = await register({ token });
    const errorObject = res as { error: FetchBaseQueryError };
    if (!errorObject?.error) {
      localStorageHelper.setItem(localStorageKeys.deviceToken, token);
      localStorageHelper.setItem(localStorageKeys.isNotificationSettingEnabled, 'true')
      dispatch(setfirebaseMessagingToken(token));
    } else {
      dispatch(setIsNotificationError(true));
    }
  };

  const deleteToken = async () => {
    try {
      dispatch(setIsNotificationError(false));
      dispatch(setIsNotificationLoading(true));
      const token = localStorageHelper.getItem(localStorageKeys.deviceToken);
      if (token) {
        const res = await unregister({ token });
        const errorObject = res as { error: FetchBaseQueryError };
        if (!errorObject?.error) {
          localStorageHelper.removeItem(localStorageKeys.deviceToken);
          localStorageHelper.setItem(localStorageKeys.isNotificationSettingEnabled, 'false')
          dispatch(setfirebaseMessagingToken(undefined));
        } else {
          dispatch(setIsNotificationError(true));
        }
      }
      dispatch(setIsNotificationLoading(false));
    } catch (e) {
      dispatch(setIsNotificationError(true));
      dispatch(setIsNotificationLoading(false));
    }
  };

  const listenForNotificationAction = () => {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          navigateWithEmbedUrl(appRoutes.MESSAGES_PAGE,
            "pm/inbox?show_id=" + notification.notification.data.id
          );
        }
      )
        .then(() => {
          console.log("listenForNotificationAction");
        })
        .catch((e) => console.log("error", e));
    }
  };

  return {
    listenForNotificationAction,
    initialize,
    deleteToken,
    isTokenInLocalStorage,
  };
};
