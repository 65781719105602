import { useContext, useEffect } from "react";
import { PopUpContext } from "app/context/PopUpContext";
import { BotModal } from "components/PopUp/BotModal/BotModal";
import { useNavigateTo } from "./useNavigateTo";
import { appRoutes, config } from "app/constants";
import { useSignup } from "./useSignup";
import { setBannerAlert } from "app/slices/appSlice";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { useIsAuthenticated } from "@azure/msal-react";
import { useGetPromptsMutation } from "app/services/prompts.service";
import {
  useDeleteInterestMutation,
  usePutInterestsMutation,
} from "app/services/interests.service";
import {
  PromptAction,
  PromptActionTypes,
  PromptDTO,
  PromptFrom,
  PromptsPostData,
  PromptType,
} from "DTOs/PromptDTO";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAuthContext } from "app/providers/auth";
export interface usePromptsProps {
  callApiOnInitialize?: boolean;
  from?: PromptFrom;
  data?: PromptsPostData;
}
export const usePrompts = ({
  callApiOnInitialize = false,
  from = PromptFrom.home,
  data,
}: usePromptsProps) => {
  const { enableInterventions, prompts: promptsFlags } = useFlags();
  const [updateInterests] = usePutInterestsMutation();
  const [deleteInterest] = useDeleteInterestMutation();
  const { navigateTo, navigateWithEmbedUrl } = useNavigateTo();
  const { signup } = useSignup();
  const popUpContext = useContext(PopUpContext);
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthContext();

  const miniProfile = useAppSelector((state) => state.user.userData);
  const getPromptsArgs = {
    userId: isAuthenticated ? miniProfile?.wtId ?? 0 : 0,
    from,
    data,
  };
  const [getPrompts] = useGetPromptsMutation();

  const callGetPromptsOnHookCall =
    ((isAuthenticated && miniProfile?.wtId) || !isAuthenticated) && callApiOnInitialize;

  useEffect(() => {
    if (callGetPromptsOnHookCall) {
      getAllPrompts();
    }
  }, [miniProfile?.wtId, isAuthenticated, enableInterventions]);

  const getAllPrompts = async (arg?: { data?: PromptsPostData; from?: PromptFrom }) => {
    const isInterventionEnable = enableInterventions && promptsFlags?.[from];
    if (isInterventionEnable && (!miniProfile || miniProfile?.onboardingCompleted)) {
      await getPrompts({
        ...getPromptsArgs,
        data: arg?.data ?? getPromptsArgs.data,
        from: arg?.from ?? getPromptsArgs.from,
      })
        .unwrap()
        .then((res) => {
          if (res.prompts) {
            const prompt = res?.prompts?.[0];
            if (prompt?.display) {
              handlePrompts(prompt);
            }
          }
        });
    }
  };

  const promptActions = (action: PromptAction | undefined) => {
    switch (action?.type) {
      case PromptActionTypes.ignore:
        break;
      case PromptActionTypes.signIn:
        navigateTo(appRoutes.SIGNIN);
        break;
      case PromptActionTypes.createAccount:
        signup();
        break;
      case PromptActionTypes.viewDirectMessage:
        navigateTo(
          appRoutes.MESSAGES_PAGE,
          `?show_id=${action.data.message_id}`
        );
        break;
      case PromptActionTypes.new_discussion:
        navigateTo(appRoutes.CREATE_POST_PAGE);
        break;
      case PromptActionTypes.reply:
      case PromptActionTypes.read_post:
        navigateWithEmbedUrl(
          appRoutes.HOME_PAGE,
          `post/${action.data.thread_id}?pid=${action.data.post_id}`
        );
        break;

      case PromptActionTypes.add_interest:
        updateInterests({
          ghp_ref_user_id: miniProfile?.wtId,
          ghp_ref_interest_id: `${action.data.interest_id}`,
        });
        break;
      case PromptActionTypes.remove_interest:
        deleteInterest({
          ghp_ref_user_id: miniProfile?.wtId,
          ghp_ref_interest_id: action.data.interest_id,
        });
        break;
      case PromptActionTypes.adjust_settings:
        navigateTo(appRoutes.SETTINGS_PAGE);
        break;
    }
  };

  const handlePrompts = (prompt: PromptDTO) => {
    if (prompt) {
      switch (prompt.type) {
        case PromptType.banner:
          dispatch(
            setBannerAlert({
              title: prompt.title_text,
              description: prompt.body_text,
              action: prompt.actions?.[0]?.action,
            })
          );
          break;
        case PromptType.popup:
        default:
          popUpContext.showModal({
            body: (
              <BotModal
                buttons={prompt.actions?.map((promptAction) => ({
                  text: promptAction?.action?.data?.display_text ?? config.EMPTY_STRING,
                  onClick: () => {
                    promptActions(promptAction.action);
                    popUpContext.closeModal();
                  },
                }))}
                title={prompt.title_text}
                subTitle={prompt.bot_name}
                summary={prompt.body_text}
              />
            ),
          });
          break;
      }
    }
  };

  return { getAllPrompts, promptActions };
};
