import { Capacitor } from "@capacitor/core";
import React, { useEffect, useRef } from "react";

export const useAppEffect = (effect: () => void, deps?: React.DependencyList | undefined) => {
    const effectRan = useRef(false);
    useEffect(() => {
        if (effectRan.current === true || process.env.REACT_APP_ENV !== "DEV" || Capacitor.isNativePlatform())
            effect();
        return () => {
            effectRan.current = true
        }
    }, deps)
}