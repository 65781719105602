import { forumWebhookProvider } from "./provider/forumWebhook.provider";

const extendedApi = forumWebhookProvider.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation<void, { token: string }>({
            query: (params) => ({
                url: `/notifications/register`,
                method: "POST",
                body: {
                    token: params.token,
                }
            }),
            invalidatesTags: ["Notifications"]

        }),
        unregister: builder.mutation<void, { token: string }>({
            query: (params) => ({
                url: `/notifications/unregister`,
                method: "POST",
                body: {
                    token: params.token,
                }
            }),
            invalidatesTags: ["Notifications"]

        }),

    }),

    overrideExisting: false,
});
export const { useRegisterMutation, useUnregisterMutation } = extendedApi;
