import { Deploy } from "cordova-plugin-ionic";
import { IDeployConfig } from "cordova-plugin-ionic/dist/IonicCordova";
import { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import useDetectMobilePlatform from "./useDetectMobilePlatform";
import { FeatureFlags } from "app/types/FeatureFlags";
import { SplashScreen } from "@capacitor/splash-screen";

export const useManualUpdates = () => {
  const platform = useDetectMobilePlatform();
  const { liveUpdatesChannel, enableLiveUpdates } = useFlags() as FeatureFlags;

  useEffect(() => {
    performManualUpdateDuringSplashScreen().catch(console.error);
  }, [enableLiveUpdates, liveUpdatesChannel]);

  const performManualUpdateWhileInBackground = async () => {
    if (platform.isMobileDevice && enableLiveUpdates) {
      const deployConfig = await Deploy.getConfiguration();
      if (deployConfig.updateMethod == "none") {
        const config: IDeployConfig = {
          channel: liveUpdatesChannel || "Production",
        };
        await Deploy.configure(config);

        await Deploy.sync({ updateMethod: "background" }, (percentDone) => {
          console.log(`Update is ${percentDone}% done!`);
        });
      }
    }
  };

  const performManualUpdateDuringSplashScreen = async () => {
    try {
      if (platform.isMobileDevice && enableLiveUpdates) {
        const currentVersion = await Deploy.getCurrentVersion();
        const resp = await Deploy.sync(
          { updateMethod: "auto" },
          (percentDone) => {
            console.log(`Update is ${percentDone}% done!`);
            if (percentDone == 100) {
              SplashScreen.show({ autoHide: true, showDuration: 1000 });
            }
          }
        );
        if (!currentVersion || currentVersion.versionId !== resp?.versionId) {
          // We found an update, and are in process of redirecting you since you put auto!
        } else {
          // No update available
          await SplashScreen.hide();
        }
      }
    } catch (error) {
      await SplashScreen.hide();
      throw error;
    }
  };
};
