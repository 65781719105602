export const setImageAuthToken = (authtoken: string, onLoad?: () => void) => {
  try {
    const image = document.getElementById("image-token") as HTMLImageElement;
    image.src = `${process.env.REACT_APP_FORUM_SUBDOMAIN}/register/dologin?authtoken=${authtoken}&remember=1`;
    if (onLoad) {
      image.onload = onLoad;
    }
  } catch (error) {
    // console.error("Error setting Image token.", error);
  }
};
