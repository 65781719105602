import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ShowBottomSheetProps } from "app/context/BottomSheetContext";

interface BottomSheetProps {
  onClose: () => void;
  isOpen: boolean;
  bottomSheetProps: ShowBottomSheetProps;
}
export const BottomSheet: React.FC<BottomSheetProps> = ({
  bottomSheetProps,
  isOpen,
  onClose,
}) => {
  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
        variant={"outline"}
        size="xl"
        id="asdModal"
      >
        <ModalOverlay height="100vh" />
        <ModalContent
          backgroundColor={"#f5f5f5"}
          id="bottom-sheet"
          justifyContent={"start"}
          position="fixed"
          bottom="0px"
          mb="0"
          borderRadius="1.75rem 1.75rem 0px 0px"
          maxW="lg"
        >
          {bottomSheetProps.closeButton && (
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
          )}
          <ModalBody>{bottomSheetProps.body}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
