import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import appReducer from "app/slices/appSlice";
import challenges from "app/slices/challengeSlice";
import notifications from "app/slices/notificationsSlice";
import userReducer from "app/slices/userSlice";
import counters from "app/slices/countersSlice";
import pubNubReducer from "app/slices/pubNubSlice";
import authReducer from "app/slices/authSlice";
import { displayNameProvider } from "app/services/provider/displayName.provider";
import {
  forumWebhookProvider,
  forumWebhookUnAuthProvider,
} from "app/services/provider/forumWebhook.provider";
import { sequelaeProvider } from "app/services/provider/sequelae.provider";
import { interestsExtendedApi } from "app/services/interests.service";
import { externalServicesProvider } from "app/services/provider/externalServices.provider";
import listenerMiddleware from "./listenerMiddleware";
import urlReducer from "app/slices/urlSlice";
import { enrollmentProvider } from "enrollment/service/enrollment.provider";

const rootPersistConfig = {
  key: "root",
  storage,
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
};

const rootPersistedReducer = persistReducer(rootPersistConfig, userReducer);
const authPersistedReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    user: rootPersistedReducer,
    app: appReducer,
    auth: authPersistedReducer,
    challenges,
    notifications,
    counters,
    pubNub: pubNubReducer,
    url: urlReducer,
    [enrollmentProvider.reducerPath]: enrollmentProvider.reducer,
    [displayNameProvider.reducerPath]: displayNameProvider.reducer,
    [forumWebhookProvider.reducerPath]: forumWebhookProvider.reducer,
    [forumWebhookUnAuthProvider.reducerPath]:
      forumWebhookUnAuthProvider.reducer,
    [sequelaeProvider.reducerPath]: sequelaeProvider.reducer,
    [enrollmentProvider.reducerPath]: enrollmentProvider.reducer,
    [interestsExtendedApi.reducerPath]: interestsExtendedApi.reducer,
    [externalServicesProvider.reducerPath]: externalServicesProvider.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(
        forumWebhookProvider.middleware,
        sequelaeProvider.middleware,
        enrollmentProvider.middleware,
        externalServicesProvider.middleware,
        forumWebhookUnAuthProvider.middleware,
        displayNameProvider.middleware
      )
      .prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);
