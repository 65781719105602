import { PromptDTO, PromptsPostData } from "DTOs/PromptDTO";
import { sequelaeProvider } from "./provider/sequelae.provider";

const extendedApi = sequelaeProvider.injectEndpoints({
    endpoints: (builder) => ({
        getPrompts: builder.mutation<
            { prompts: Array<PromptDTO> },
            { userId: number, from: string, data?: PromptsPostData }
        >({
            invalidatesTags: ["Prompts"],
            query: (args) => {
                const { userId, data, from } = args;
                return {
                    url: "v1/prompts/",
                    method: "Post",
                    body: {
                        user_id: userId,
                        api_key: `${process.env.REACT_APP_SEQUELAE_API_KEY}`,
                        action: from,
                        data
                    },
                };
            },

        }),


    }),
    overrideExisting: false,
});
export const { useGetPromptsMutation } = extendedApi;
