import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum AuthStateStatus {
  None = "None",
  FetchTokenSuccess = "FetchTokenSuccess",
  LoginInProgress = "LoginInprogress",
  LoginSuccess = "LoginSuccess",
  LoginFailed = "LoginFailed",
  LoginCancelled = "LoginCancelled",
  LogoutInProgress = "LogoutInprogress",
  LogoutSuccess = "LogoutSuccess",
  LogoutFailed = "LogoutFailed",
  RefreshTokenSuccess = "RefreshTokenSuccess",
  SignupInProgress = "SignupInprogress",
  SignupSuccess = "SignupSuccess",
  SignupFailed = "SignupFailed",
  SignupCancelled = "SignupCancelled",
}

export type AuthenticatedUser = {
  username: string;
  email?: string;
  isNewUser?: boolean;
  extension_Userhassignedin?: boolean;
  oid?: string
};

interface authState {
  status: AuthStateStatus;
  isAuthenticated: boolean;
  user: AuthenticatedUser | null;
}
const initialState: authState = {
  status: AuthStateStatus.None,
  isAuthenticated: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onLoginStarted: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.LoginInProgress;
    },
    onLoginSuccess: (state, action: PayloadAction<AuthenticatedUser>) => {
      state.isAuthenticated = true;
      state.status = AuthStateStatus.LoginSuccess;
      state.user = action.payload;
    },
    onLoginFailed: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.LoginFailed;
    },
    onLogoutStarted: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.LogoutInProgress;
    },
    onLogoutSuccess: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.LogoutSuccess;
      state.isAuthenticated = false;
      state.user = null;
    },
    onLogoutFailed: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.LogoutFailed;
    },
    onLoginCancelled: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.LoginCancelled;
    },
    onSignupSuccess: (state, action: PayloadAction<AuthenticatedUser>) => {
      // set the newUser flag to true;
      state.status = AuthStateStatus.SignupSuccess;
      state.isAuthenticated = true;
      state.user = { ...action.payload, isNewUser: true };
    },
    onSignupFailed: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.SignupFailed;
    },
    onSignupStarted: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.SignupInProgress;
    },
    onSignupCancelled: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.SignupCancelled;
    },
    onFetchTokenSuccess: (state, action: PayloadAction<void>) => {
      state.status = AuthStateStatus.FetchTokenSuccess;
    },
    resetAuthState: (state) => {
      state.status = AuthStateStatus.None;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export default authSlice.reducer;
export const {
  onLoginStarted,
  onLoginSuccess,
  onLogoutStarted,
  onLogoutSuccess,
  onLoginFailed,
  onLogoutFailed,
  onLoginCancelled,
  resetAuthState,
  onSignupStarted,
  onSignupSuccess,
  onSignupFailed,
  onSignupCancelled,
  onFetchTokenSuccess,
} = authSlice.actions;
