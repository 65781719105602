import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ShowModalProps } from "app/context/PopUpContext";
import useDetectMobilePlatform from "app/hooks/useDetectMobilePlatform";

interface PopUpProps {
  onClose: () => void;
  isOpen: boolean;
  modalProps: ShowModalProps;
}
export const PopUp: React.FC<PopUpProps> = ({
  modalProps,
  isOpen,
  onClose,
}) => {
  const { isDesktopView } = useDetectMobilePlatform();
  return (
    <Modal
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      size={isDesktopView ? "lg" : "sm"}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent m={5} borderRadius="15px">
        {modalProps.title && <ModalHeader>{modalProps.title}</ModalHeader>}
        {modalProps.closeButton && <ModalCloseButton />}
        <ModalBody p={0}>{modalProps.body} </ModalBody>

        {modalProps.footer && (
          <ModalFooter justifyContent="flex-start">
            {modalProps.footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
