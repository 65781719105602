import { acquireAccessToken } from "app/auth/helper";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Signals } from "app/constants/Signals";
import { updateUserLevel, setEmailVerified } from "app/slices/userSlice";

import { store } from "app/state/store";
import { CountersState, setCounters } from "app/slices/countersSlice";
import { useAppEffect } from "./useAppEffect";
import { setOnlineStatus } from "app/utils";
import { useAuthContext } from "app/providers/auth";
const ConnetionLostErr =
  "Server timeout elapsed without receiving a message from the server.";
const ConnetionUnknowErr =
  "WebSocket closed with status code: 1006 (no reason given).";
let connection: HubConnection | null;
export const useSignalR = () => {
  const { isAuthenticated } = useAuthContext();
  useAppEffect(() => {
    if (isAuthenticated) initSignalR();
  }, [isAuthenticated]);

  const initSignalR = () => {
    if (connection) {
      connection?.stop();
      connection = null;
    }
    connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_FORUM_WEBHOOK_API_BASE_URL}/hubs`, {
        accessTokenFactory: acquireAccessToken,
        headers: { ws: "true" },
      })
      .withAutomaticReconnect([1000, 2000, 3000, 4000])
      .build();
    connection.start().then(() => {
      handleSubscriptions(connection);
    });

    connection.onreconnecting((e) => {
      if (
        e?.message.includes(ConnetionLostErr) ||
        e?.message.includes(ConnetionUnknowErr)
      ) {
        setOnlineStatus();
      }
    });
    connection.onreconnected((e) => {
      setOnlineStatus();
    });
    const handleSubscriptions = (connection: HubConnection | null) => {
      const dispatch = store.dispatch;
      connection?.on(Signals.Level, (message: string) => {
        if (message) dispatch(updateUserLevel(message));
      });
      connection?.on(Signals.EmailVerified, (message: boolean) => {
        if (message) dispatch(setEmailVerified());
      });
      connection?.on(Signals.Counters, (message: CountersState) => {
        if (message) dispatch(setCounters(message));
      });
    };
  };

  const closeSignalR = () => {
    connection?.stop();
    connection = null;
  };
  return { initSignalR, closeSignalR };
};
