import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import {
  MessageEvent,
  StatusEvent,
  PresenceEvent,
  SignalEvent,
  MessageActionEvent,
  FileEvent,
  ObjectsEvent,
} from "pubnub";

interface PubNubState {
  statusEvent?: StatusEvent;
  messageEvent?: MessageEvent;
  presenceEvent?: PresenceEvent;
  signalEvent?: SignalEvent;
  messageActionEvent?: MessageActionEvent;
  fileEvent?: FileEvent;
  objectsEvent?: ObjectsEvent;
  userId?: string;
  ipAddress?: string;
}

const initialState: PubNubState = {};

export const pubNubSlice = createSlice({
  name: "pubNub",
  initialState,
  reducers: {
    setMessageEvent: (state, action: PayloadAction<MessageEvent>) => {
      state.messageEvent = action.payload;
    },
    setPresenceEvent: (state, action: PayloadAction<PresenceEvent>) => {
      state.presenceEvent = action.payload;
    },
    setSignalEvent: (state, action: PayloadAction<SignalEvent>) => {
      state.signalEvent = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setIpAddress: (state, action: PayloadAction<string>) => {
      state.ipAddress = action.payload;
    },
  },
});

export const { setMessageEvent, setPresenceEvent, setSignalEvent, setUserId, setIpAddress } =
  pubNubSlice.actions;
export default pubNubSlice.reducer;
