import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import { Constants } from "app/constants";

//check for desktop platform on touchscreen laptop
function isWindows(): boolean {
  if (!window || !navigator) {
    return false;
  }
  const a: string =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigator.userAgent || navigator.vendor || (window as any).opera;

  return /windows/i.test(a);
}
const useDetectMobilePlatform = () => {
  const isMobileDevice = Capacitor.isNativePlatform() ?? false;
  const isDesktopView =
    (!isMobileDevice && isPlatform(Constants.DesktopPlatform)) || isWindows();
  const isMobileWebView =
    !isMobileDevice &&
    !isDesktopView &&
    isPlatform(Constants.MobileDesktopPlatform);
  return {
    isMobileWebView,
    isDesktopView,
    isMobileDevice,
  };
};

export default useDetectMobilePlatform;
