import { useDisclosure } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { PopUp } from "components/PopUp/PopUp";
import { PopUpContext, ShowModalProps } from "app/context/PopUpContext";
interface PopUpProviderProps {
  children: ReactNode;
}
export const PopUpProvider: React.FC<PopUpProviderProps> = ({ children }) => {
  const [popUpProps, setPopUpProps] = useState<ShowModalProps>({
    body: <div></div>,
    title: <div></div>,
  });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const showModal = (showModalProps: ShowModalProps) => {
    setPopUpProps(showModalProps);
    onOpen();
  };
  const closeModal = onClose;

  return (
    <PopUpContext.Provider
      value={{ showModal: showModal, closeModal: closeModal }}
    >
      <PopUp modalProps={popUpProps} isOpen={isOpen} onClose={closeModal} />
      {children}
    </PopUpContext.Provider>
  );
};
