// References
// https://capacitorjs.com/docs/guides/deep-links
// https://devdactic.com/setup-deep-links-capacitor
// https://developer.android.com/training/app-links/deep-linking

import { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useNavigateTo } from "app/hooks/useNavigateTo";
import { appRoutes } from "app/constants";

const AppUrlListener: React.FC<any> = () => {
  const { navigateTo, navigateWithEmbedUrl } = useNavigateTo();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const path = event.url.split(process.env.REACT_APP_BASE_URL!).pop();

      if (!path) return;

      if (path.includes("?p=")) {
        const parts = path.split("?p=");
        const route = parts[0];
        const url = parts[1];

        navigateWithEmbedUrl(route as appRoutes, url);
      } else {
        navigateTo(path);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
