// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";
const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};
// 2. Call `extendTheme` and pass your custom values
export default extendTheme({
  components: {
    Button: {
      baseStyle: {
        borderRadius: "16",
      },
      defaultProps: {
        colorScheme: "brand",
      },
      variants: {
        outline: {
          border: "3px solid ",
          borderColor: "#3794AA",
          color: "#3794AA",
          fontWeight: "700",
          _hover: {
            background: "#E6FFFA",
          },
        },
      },
    },
    IconButton: {
      baseStyle: {
        color: "#3794AA",
        bg: "transparent",
      },
    },
    Text: {
      baseStyle: {
        cursor: "default",
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            "input[type='text']:disabled ": {
              background: "#F0F0F0",
            },

            "input[type='text']:disabled + label ": {
              background: "transparent",
            },

            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 2,
              my: 2,
              px: 1,
              transformOrigin: "left top",
              color: "var(--chakra-colors-appGray)",
              fontWeight: "normal",
              fontSize: "var(--chakra-fontSizes-sm)",
            },
          },
        },
      },
    },
  },
  styles: {
    global: {
      "html,body": {
        bg: "#FFF", //"#F7F7F7",
        color: "#595959",
      },
      a: {
        backgroundColor: "transparent",
        color: "#3880ff",
        textDecoration: "underline",
      },
    },
  },
  colors: {
    highlight: "#0A7AFF",
    highlightGreen: "#C7EFB4",
    highlightBlue: "#9BE1E1",
    highlightGrey: "#F2F2F2",
    appGray: "#999999",
    appGrayLighter: "#f5f5f5",
    appGrayDarker: "#888888",
    appRedLight: "#FF5024",
    appSecondaryGray: "#70707099",
    appRedDark: "#B72002",
    mainGreenDarker: "#4DB748",
    mainGreenLighter: "#8ED372",
    secondaryGreenDarker: "#468822",
    secondaryGreenLighter: "#88B835",
    secondaryPurpleLighter: "#9A88E8",
    secondaryPurpleDarker: "#6A5AA3",
    appRed: "#D13745",
    appGreen: "#17AB3C",

    brand: {
      main: "#3794AA",
      50: "#13707F",
      100: "#c1dcf1",
      200: "#9dc5e2",
      300: "#77aed5",
      400: "#13707F",
      500: "#3794AA",
      600: "#13707F",
      700: "#1d4562",
      800: "#0c2a3d",
      900: "#000000",
      1000: "#595959",
    },
  },
});
