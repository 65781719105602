import { Capacitor } from "@capacitor/core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MiniProfileDTO, UserDataDTO } from "DTOs/UserDataDTO";
import { UserConsentsDto } from "app/services/provider/types";
import { RootState } from "app/state/store";
import { LoadingTypes } from "app/types/LoadingTypes";

const DEFAULT_USERNAME = "Guest";

export interface AppState {
  loadingStatus: LoadingTypes;
  userConsents: UserConsentsDto<boolean> | null;
  refUserId: number;
  isProfileEditSuccess: boolean | null;
  userData: UserDataDTO | null;
  isAuthorized: boolean;
  miniProfile: MiniProfileDTO;
}

const initialState: AppState = {
  loadingStatus: LoadingTypes.idle,
  userConsents: null,
  isProfileEditSuccess: null,
  refUserId: 0,
  userData: null,
  isAuthorized: true,
  miniProfile: { username: DEFAULT_USERNAME } as MiniProfileDTO,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoadingStatus: (state, action: PayloadAction<LoadingTypes>) => {
      state.loadingStatus = action.payload;
    },
    setIsUserConsent: (state, action: PayloadAction<UserConsentsDto<boolean>>) => {
      state.userConsents = action.payload;
    },
    setRefUserId: (state, action: PayloadAction<number>) => {
      state.refUserId = action.payload;
    },
    setIsProfileEditSuccess: (state, action: PayloadAction<boolean>) => {
      state.isProfileEditSuccess = action.payload;
    },
    setIsAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserDataDTO>) => {
      if (!Capacitor.isNativePlatform()) {
        const account = globalThis.msalInstance.getActiveAccount();
        if (!account) return;
      }
      const oldToken = state.userData?.authToken;
      state.userData = { ...action.payload };
      state.refUserId = action.payload.id;
      if (oldToken) {
        state.userData.authToken = oldToken;
      }
      setMiniProfile(state);
    },
    updateUserLevel: (state, action: PayloadAction<string>) => {
      if (!state.userData) return;
      state.userData.level = action.payload;
      setMiniProfile(state);
    },
    setEmailVerified: (state) => {
      if (!state.userData) return;
      state.userData.verifiedAccount = true;
    },
    setEnrollmentStatus: (state, action: PayloadAction<string>) => {
      if (!state.userData) return;
      state.userData.enrollmentStatus = action.payload;
    },
    setEnrollmentGuid: (state, action: PayloadAction<string>) => {
      if (!state.userData) return;
      state.userData.enrollmentGuid = action.payload;
    },
    resetAppState: () => initialState,
  },
});

const setMiniProfile = (state: AppState) => {
  state.miniProfile = {
    username: state.userData!.username,
    avatarUrl: state.userData!.avatarUrl,
    level: state.userData!.level,
    progress: state.userData!.progress,
    wtId: state.userData!.wtId,
  };
};

export const {
  setLoadingStatus,
  setIsUserConsent,
  setRefUserId,
  setIsProfileEditSuccess,
  setUserData,
  setIsAuthorized,
  resetAppState,
  updateUserLevel,
  setEmailVerified,
  setEnrollmentStatus,
  setEnrollmentGuid,
} = userSlice.actions;
export default userSlice.reducer;
