import { Capacitor } from "@capacitor/core";
import { Constants } from "app/constants";
import { Media } from "@capacitor-community/media";
import { Filesystem, Directory } from "@capacitor/filesystem";
export const platform = Capacitor.getPlatform().toUpperCase() as "ANDROID" | "IOS" | "WEB";

const platfromRedirectUri = {
  WEB: `${process.env.REACT_APP_BASE_URL}`,
  IOS: Constants.BASE_URL_IOS,
  ANDROID: Constants.BASE_URL_ANDROID,
};
export const getPlatformAutRedirectUri = () =>
  platfromRedirectUri[platform as keyof typeof platfromRedirectUri];

export const isPlatformIosSafariBrowser = () => {
  const is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent),
    is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  return is_ios && is_safari;
};

export const saveImage = async (base64: string) => {
  //const media = new Media();
  // Save the wallpaper locally to Cache folder
  const wallpaperTempFile = `${new Date().getTime()}.png`;
  const wallpaperAsBase64 = base64;
  const wallpaperTemp = await Filesystem.writeFile({
    path: wallpaperTempFile,
    data: wallpaperAsBase64,
    directory: Directory.Cache,
  });
  console.log(" wallpaperTemp", { wallpaperTemp });
  // Get or create the album
  // @TODO Simplify when issue https://github.com/capacitor-community/media/issues/6 is fixed
  const albumName = "LoreHealth";
  let albumIdentifier;

  let albums = await Media.getAlbums();
  if (platform === "IOS") {
    // Handle albums
    console.log(" albums", { albums });
    albumIdentifier = albums.albums.find((a) => a.name === albumName)?.identifier || null;

    console.log(" albumIdentifier", { albumIdentifier });
    if (!albumIdentifier) {
      console.log(albumName + " album doesn't exist, create new", { albumIdentifier });
      // Doesn't exist, create new album
      await Media.createAlbum({ name: albumName });
      albums = await Media.getAlbums();
      albumIdentifier = albums.albums.find((a) => a.name === albumName)?.identifier;
    }
  } else if (platform === "ANDROID") {
    if (!albums.albums.some((s) => s.name === albumName)) {
      await Media.createAlbum({ name: albumName });
    }
  }

  const response = await Media.savePhoto({
    path: wallpaperTemp.uri,
    album: platform === "IOS" ? albumIdentifier : albumName,
  });
  // .then(() => console.log("Image has been saved"))
  // .catch((e) => console.error(e));
  return response;
};
