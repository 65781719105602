import { Constants } from "app/constants";
import { useAppToast } from "app/hooks/useAppToast";
import { useNotification } from "app/hooks/useNotification";
import { onLogoutSuccess, resetAuthState } from "app/slices/authSlice";
import { resetAppState } from "app/slices/userSlice";
import { store } from "app/state/store";

const dispatch = store.dispatch;

export const signout = async (redirectUri?: string) => {

  const user = store.getState().user.userData;
  if (user?.authToken) {
    const image = document.getElementById("image-token") as HTMLImageElement;
    image.src = `${process.env.REACT_APP_FORUM_SUBDOMAIN}/register/logout?authtoken=${user.authToken}`;
    checkImageComplete(redirectUri);
  } else {
    completeSignout(redirectUri);
  }

  window.heap.resetIdentity();
};

function checkImageComplete(redirectUri?: string) {
  const interval = window.setInterval(() => {
    const image = document.getElementById("image-token") as HTMLImageElement;
    if (image.complete) {
      completeSignout(redirectUri);
      window.clearInterval(interval);
    }
  }, 100);
}

const completeSignout = async (redirectUri?: string) => {
  // ToDo: enable delete tokein
  // const { deleteToken } = useNotification();
  // await deleteToken();
  dispatch(resetAppState());
  dispatch(resetAuthState());
  localStorage.clear();
  sessionStorage.clear();
  const instance = globalThis.msalInstance;
  const logoutObject: any = {
    account: instance.getActiveAccount(),
  };
  if (redirectUri) {
    logoutObject.postLogoutRedirectUri = redirectUri;
  }

  await instance.logoutRedirect(logoutObject);
};
