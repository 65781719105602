import {
  AccountInfo,
  BrowserAuthError,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { appRoutes } from "app/constants";
import axios from "axios";
import { signout } from "./signoutHelper";
import { Capacitor } from "@capacitor/core";
import { getAccessToken } from "./authenticationSession";
import { localStorageHelper } from "app/helpers/localStorageHelper";

export const getScopes = () => {
  return ["Forum.ReadWrite"].map(
    (scope) => `${process.env.REACT_APP_FORUM_WEBHOOK_API_APP_ID_URI}/${scope}`
  );
};

// The checkIfNewUser function will get the newUser flag from local storage
// if it is true meaning the active account has just signed up
// we will not use the mobile generated token, instead we are going to use
// the web or the instance.acquireTokenSilent.
// this workaround is only temp, until we found solution that
// will support the signup natively.
const notANewUser = (): boolean => {
  const isNewUser = localStorageHelper.getItem("signup.new-user") as boolean;
  return !isNewUser;
};

export const acquireAccessToken = async (): Promise<string> => {
  let token = "";

  // Use this when in native platform and for existing users only
  if (Capacitor.isNativePlatform() && notANewUser()) {
    token = await getAccessToken();
    return token;
  }

  // Use this instance when in web or a newly signed up user.
  const instance: PublicClientApplication = globalThis.msalInstance;
  const accounts = instance.getAllAccounts();

  const scopes = getScopes();
  try {
    const authResult = await instance.acquireTokenSilent({
      scopes,
      account: accounts[0],
    });
    token = authResult.accessToken;
  } catch (error) {
    // in case if silent token acquisition fails, fallback to an interactive method
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      if (accounts.length) {
        try {
          signout(appRoutes.SIGNIN);
        } catch (error) {
          console.log("acquireTokenPopup - acquiring token", { error });
        }
      }
    }
  }
  return token;
};

export const acquireAccessTokenEnrollment = async () => {
  let token = sessionStorage.getItem("enrollmentToken");
  if (token) return token;
  const result = await axios.get(
    `${process.env.REACT_APP_FORUM_WEBHOOK_API_BASE_URL}/api/EnrollmentToken`
  );
  if (result.status === 200 && result.data) {
    return result.data;
  }

  return null;
};

export const getIdTokenClaims = (account: AccountInfo | null) => {
  if (account) {
    return account?.idTokenClaims;
  }
  return null;
};
