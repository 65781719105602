import { NavigationClient, NavigationOptions } from "@azure/msal-browser";
import { Capacitor } from "@capacitor/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { getPlatformAutRedirectUri, platform } from "app/helpers/hybridAppHelper";
import { Constants, appRoutes } from "app/constants";

/**
 * Extending the default NavigationClient allows you to overwrite just navigateInternal while continuing to use the default navigateExternal function
 * If you would like to overwrite both you can implement INavigationClient directly instead
 */
export default class CustomNavigationClient extends NavigationClient {
  constructor() {
    super();
  }

  async navigateExternal(url: string, options: NavigationOptions) {
    if (Capacitor.isNativePlatform() && platform === "ANDROID") {
      const browser = InAppBrowser.create(url, "_blank", {
        location: "no",
        clearcache: "no",
        clearsessioncache: "no",
        hidenavigationbuttons: "yes",
        hideurlbar: "yes",
        fullscreen: "yes",
      });
      browser.on("loadstart").subscribe((event) => {
        if (event.url.includes("#state")) {
          // Close the in app browser and redirect to localhost + the state parameter
          browser.close();

          const domain = event.url.split("#")[0];
          url = event.url.replace(
            domain,
            `${getPlatformAutRedirectUri()}/${Constants.TRIGGER_WEBVIEW_NAVIGATION}`
          );
          window.location.href = url;
        } else if (event.url.indexOf(Constants.BASE_URL_ANDROID) === 0) {
          browser.close();
          if (event.url.includes("signin")) {
            window.location.href = `${getPlatformAutRedirectUri()}${appRoutes.SIGNIN}`;
            return;
          }

          window.location.href = `${getPlatformAutRedirectUri()}/${
            Constants.TRIGGER_WEBVIEW_NAVIGATION
          }`;
        }
      });
    } else {
      if (options.noHistory) {
        window.location.replace(url);
      } else {
        window.location.assign(url);
      }
    }

    return true;
  }
}
